
.practical-train-operate {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 30px;
    font-size: 14px;
    color: #1b162a;
    .title-box {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #060111;
        font-weight: 700;
        &::before {
            content: "";
            display: block;
            width: 6px;
            height: 12px;
            margin-right: 8px;
            background-color: #2821fc;
            border-radius: 3px;
        }
    }
    .form-box {
        margin: 32px 20px 18px;
        .content {
            display: flex;
            .container {
                .level {
                    margin-bottom: 21px;
                    font-size: 16px;
                    color: #332d42;
                    text-align: center;
                    line-height: normal;
                    white-space: nowrap;
                }
                .on-line,
                .award {
                    width: 100%;
                    box-sizing: border-box;
                    margin-bottom: 23px;
                    white-space: nowrap;
                }
                .award {
                    margin-bottom: 0;
                }
            }
            .label-box {
                width: 107px;
                .on-line,
                .award {
                    margin-left: 0;
                    text-align: end;
                }
                .on-line::before,
                .award::before {
                    content: "*";
                    margin-right: 3px;
                    color: #ff3e6c;
                }
            }
            .input-box {
                flex: 1;
                ::v-deep .el-form-item__content {
                    display: flex;
                    .container {
                        box-sizing: border-box;
                        flex: 1;
                        max-width: 250px;
                        margin-left: 9px;
                    }
                    .el-form-item__error {
                        margin-left: 23px;
                    }
                }
            }
        }
    }
    .award-flow-set {
        flex: 1;
    }
    .footer {
        margin-top: 20px;
        text-align: center;
        ::v-deep .el-button {
            width: 76px;
        }
    }
}

::v-deep .el-form {
    .el-form-item__label {
        white-space: nowrap;
        color: #1b162a;
    }
    /* 谷歌 */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* 火狐 */
    input {
        -moz-appearance: textfield;
    }
}
